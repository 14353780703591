/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.rdt_TableCell {
	height: auto !important;
}
.navbar-static .app-content {
	padding-top: 0 !important;
}
.main-menu.menu-shadow {
	box-shadow: 0 0 6px 0 rgba(34, 41, 47, 0.05);
}

.card {
	box-shadow: none !important;
	border: 1px solid rgba(0,0,0,0.08)
}

.btn:hover {
	transition: all 0.25s ease;
	box-shadow: none !important;
	opacity: 0.8;
}

.brand-text {
  color: #625f6e !important;
}

.tableMois {
	th {
		cursor: pointer;
	}

	th, td {
		width:14.28%;
		transition: all 0.25s ease;
		border: 1px solid #efefef;
	}

	td {
		height: 100px;
		border: 1px solid #efefef;
		background-color: #ffffff;
		padding: 10px;
		vertical-align: top;
	}

	.caseJour {
		cursor: pointer;
		transition: all 0.25s ease;
		// &:hover {
		// 	opacity: 0.7;
		// }
	}
	.selectedEvent {
		background-color: #625f6e !important;
	}
}
.tableCalendrier {
	th {
		cursor: pointer;
	}

	th, td {
		width:20%;
		transition: all 0.25s ease;
	}

	td .eventCard:first-child {
		margin: 0;
	}
	td.activeDrag  {
		background-color: rgba(0,0,0,0.02) !important;
	}
}
.tableJour {
	th {
		cursor: pointer;
	}

	th, td {
		transition: all 0.25s ease;
	}

	td .eventCard:first-child {
		margin: 0;
	}
	td.activeDrag  {
		background-color: rgba(0,0,0,0.02) !important;
	}
}
.creneauCol {
	cursor: pointer;
	height: 100px;
}
.emptyCol {
	cursor: pointer;
	height: 50px;
	transition: all 0.25s ease;
	&:hover {
		background-color: #f4f4f4;
	}
}
.eventCard {
	box-shadow: none !important;
	border: none !important;
	cursor: grab;
	margin: 10px 0 0;


	&:hover {
		opacity: 0.8;
	}

	hr {
		opacity: 0.5 !important;
	}
}

.actionIcon {
	cursor:pointer;

	&:hover {
		opacity: 0.8;
	}
}


.etatIcon {
	cursor:grab;
	top:6px;
	right:-6px;
	border: 2px solid #000000;
	background-color: #ffffff;
	text-align: center;
	border-radius: 0.358rem;
	margin: 0;
	font-weight: 500;
	line-height: 1;
	font-size: 1rem;
}
.imperatifIcon {
	cursor:grab;
	top:36px;
	right:-6px;
	border: 2px solid #000000;
	background-color: #ffffff;
	text-align: center;
	border-radius: 0.358rem;
	margin: 0;
	font-weight: 500;
	line-height: 1;
	font-size: 1rem;
}
.memoIcon {
	cursor:grab;
	top:66px;
	right:-6px;
	border: 2px solid #000000;
	background-color: #ffffff;
	text-align: center;
	border-radius: 0.358rem;
	margin: 0;
	font-weight: 500;
	line-height: 1;
	font-size: 1rem;
}

.form-check-input {
	cursor: pointer;
}

.radio-inline-spacing {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
	& > * {
	  margin-right: 1.5rem;
	//   margin-top: 1.5rem;
	}
}

td.dotCol, td.actionCol, td.etatCol {
	white-space: nowrap;
	width: 1px;
}
td.dataCol {
	p {
		margin:0
	}
}
.dot {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	display: inline-block;
	box-sizing: content-box;
}

.tableCalendrierList tbody th {
	background-color: #f2f2f2;
	box-shadow: none;
}

.tableCalendrierList tbody tr.trEvent {
	cursor: pointer;
}
.select__multi-value {
	margin: 0.2rem !important;
}

.btnPrint {
	&:hover {
		box-shadow: none !important;
	}
}

.btn-flatpickr {
	width: 0;
	height: 0;
	padding: 0;
	margin: 0;
}

.check-inline-spacing {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
}
.select__placeholder {
	color:#aaaaaa !important;
}

.clickTable .rdt_TableRow {
	cursor: pointer;

	// transition: all 0.25s ease;
	&:hover {
		font-weight: 600;
	}
}

.colDateAbsence {
	width: 125px;
}

.employesIcon {
	margin-top: -20px;
	left:14px;
	top:20px;
}

.memosIcon {
	margin-top: -20px;
	right:14px;
	top:20px;
}

.memo {
	font-style: italic;
	text-transform: none;
	font-weight: 500;
	padding: 5px;
	background-color: #f5f5f5;
	border-radius: 6px;
	margin-top: 10px;
}